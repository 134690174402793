<template>
  <SuccessToast
    v-if="restoreDeletedProjectSuccess"
    :duration="10000"
    :title="t('project.deleteVideos.recover.successToast.title')"
    :description="t('project.deleteVideos.recover.currentProject.successToast.description', { projectName: restoredProjectName, count: restoredProjectVideoCount })"
  />
  <SuccessToast
    v-if="restoreToAnotherProjectSuccess"
    :duration="10000"
    :title="t('project.deleteVideos.recover.successToast.title')"
    :description="t('project.deleteVideos.recover.anotherProject.successToast.description', { projectName: restoredProjectName, videoName: restoredVideoName })"
  />
  <div
    @click="onClickRecover"
    :class="[
      'video-item',
      { 'is-recovered': isRecovered },
      { 'no-hover-action': isRecovered },
    ]">
    <ConfirmRecoverVideoModal
      v-if="isRecoverModalShowing"
      @success="onRecoverSuccess"
      @restore-deleted-project-success="onRestoreDeletedProjectSuccess"
      @restore-to-another-project-success="onRestoreToAnotherProjectSuccess"
      :video="video"
      :onCloseModal="onCloseRecoverModal"/>
    <Row vertical-align="center">
      <Column width="140px">
        <div class="video-cover-image">
          <ImageView
            :source="getVideoUploadCoverImage({
              video,
              defaultCoverImage: DEFAULT_COVER_IMAGE,
              currentUser
            })"
            imageSize="small" />
          <div class="video-duration" v-if="isVideoDurationAvailable()">
            {{getVideoDuration(video)}}
          </div>
        </div>
      </Column>
      <Column minWidth="200px" isPrimaryColumn>
        <div class="video-info">
          <span class="video-title">{{video.title}}</span>
        </div>
      </Column>
      <Column width="200px">
        <TextCell :text="(video.project ?? currentProject)?.name" />
      </Column>
      <Column width="200px">
        <TextCell :text="getDays()" />
      </Column>
      <Column width="140px" isActionColumn>
        <div class="action-container">
          <Tooltip
            v-if="!isRecovered"
            class="recover-button-container">
            <template v-slot:tooltip-text>
              {{t('common:recover')}}
            </template>
            <Button
              @click="onClickRecover()"
              type="button"
              buttonStyle="text-secondary">
              <template v-slot:icon-prefix>
                <i class="fas fa-clock-rotate-left"></i>
              </template>
            </Button>
          </Tooltip>
          <span
            class="recover-success"
            v-if="isRecovered">
            <i class="fas fa-check"></i> {{t('recentlyDeleted.success')}}
          </span>
        </div>
      </Column>
    </Row>
  </div>
</template>

<script lang="ts" setup>
import _ from 'lodash';
import {
  computed, ref, toRefs, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Column from '@/modules/shared/components/molecules/column/Column.vue';
import Row from '@/modules/shared/components/molecules/row/Row.vue';
import TextCell from '@/modules/shared/components/molecules/textCell/TextCell.vue';
import { intervalToDuration } from 'date-fns';
import Tooltip from '@/modules/shared/components/atoms/tooltip/Tooltip.vue';
import ImageView from '@/modules/shared/components/atoms/imageView/ImageView.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import SuccessToast from '@/modules/shared/components/molecules/toasts/SuccessToast.vue';
import {
  getVideoDuration,
  getVideoUploadCoverImage,
} from '@/modules/videoDetail/utils/videoManagement';
import type { DeletedVideo } from '@/modules/shared/types/video.type';
import { useStore } from '../../../store/hooks';
import ConfirmRecoverVideoModal from '../../organisms/confirmRecoverVideoModal/ConfirmRecoverVideoModal.vue';

const DEFAULT_COVER_IMAGE = 'https://i.imgur.com/NhBmeTI.png';

interface DeletedVideoItemProps {
  video: DeletedVideo;
}

const props = defineProps<DeletedVideoItemProps>();
const { video } = toRefs(props);

const { t } = useI18n();
const store = useStore();

const isRecoverModalShowing = ref<boolean>(false);
const isRecovered = ref<boolean>(false);
const recoveredProjectIds = computed(() => store.state.projectDeletedVideoList.recoveredProjectIds);
const currentProject = computed(() => store.state.project?.currentProject);
const restoreDeletedProjectSuccess = ref<boolean>(false);
const restoreToAnotherProjectSuccess = ref<boolean>(false);
const restoredProjectName = ref<string>('');
const restoredProjectVideoCount = ref<number>(0);
const restoredVideoName = ref<string>('');

const currentUser = computed(() => store.state.user.currentUser);

function onClickRecover() {
  if (isRecovered.value) {
    return;
  }
  isRecoverModalShowing.value = true;
}

function onCloseRecoverModal() {
  isRecoverModalShowing.value = false;
}

function isVideoDurationAvailable(): boolean {
  return !_.isEmpty(_.get(video, 'duration', null));
}

function onRecoverSuccess() {
  isRecovered.value = true;
}

function getDays(): string {
  const { deletedAt } = video.value;

  if (!deletedAt) {
    return 'N/A';
  }

  const { days, hours } = intervalToDuration({
    start: new Date(deletedAt),
    end: new Date(),
  });

  if (!_.isNil(days) && days >= 1) {
    return t('common:dayAgo', { count: days });
  }

  if (!_.isNil(days) && days === 0) {
    if (!_.isNil(hours) && hours < 1) {
      return t('common:lessThan1HourAgo');
    }

    return t('common:hourAgo', { count: hours });
  }

  return 'N/A';
}

function onRestoreDeletedProjectSuccess({ projectName, videoCount }: { projectName: string, videoCount: number }) {
  restoredProjectName.value = projectName;
  restoredProjectVideoCount.value = videoCount;
  restoreDeletedProjectSuccess.value = true;
}

function onRestoreToAnotherProjectSuccess({ projectName, videoName }: { projectName: string, videoName: string }) {
  restoredProjectName.value = projectName;
  restoredVideoName.value = videoName;
  restoreToAnotherProjectSuccess.value = true;
}

watch(recoveredProjectIds, () => {
  if (recoveredProjectIds.value.includes(video.value.projectId)) {
    isRecovered.value = true;
  }
});

</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';

.video-status-mobile {
  display: none;
}

.video-cover-image {
  flex: 0 0 120px;
  margin-right: $spacing-base;
  position: relative;
}

.video-duration {
  position: absolute;
  bottom: 0;
  right: 0;
  color: $grey-200;
  font-size: $font-level-8;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 4px 6px;
}

.information-wrapper {
  display: flex;
  flex: 1;
  align-items: center;
  min-width: 0;
}

.title-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  .title {
    font-weight: $font-weight-bold;
    color: $grey-800;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .subtitle {
    font-size: $font-level-8;
    font-weight: $font-weight-base;
  }
}

.action-column {
  flex: 0 0 100px;
  justify-content: flex-end;
}

.deleted-at {
  display: none;
}

.mobile-action-container {
  display: none;
}

.action-container {
  text-align: right;
  span {
    white-space: nowrap;
  }
  :deep(.button) {
    width: 48px;
  }
  .tooltip-container {
    opacity: 0.8;
    transition: opacity 200ms ease;
  }
}

.list-item-container {
  cursor: pointer;
  position: relative;
  &.is-recovered {
    cursor: default;
  }
  &:hover {
    .action-container .tooltip-container {
      opacity: 1;
    }
  }
}

@media screen and (max-width: $max-layout-lg) {
  .date, .deleted-at {
    font-size: $font-size-base * 0.9;
    margin-top: 2px;
  }
}

@media screen and (max-width: $max-layout-md) {
  .deleted-at {
    display: inline;
    margin-right: 6px;
  }

  .information-wrapper {
    padding-right: 0;
    flex-direction: column;
    position: relative;
    padding-top: 0.25rem;
    align-items: flex-start;

    .action-column {
      position: absolute;
      top: 8px;
      right: 0;
    }
  }
}

</style>
