/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
import _ from 'lodash';
import type { Nullable } from '@/types/index.type';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { setStateSavingStatusByType } from '@/modules/shared/utils/stateManagement';
import type {
  ExportVideoListFromVimeo, LatestExportVideoListFromVimeo, VideoHttpHeader, VideoSourceS3,
} from '@/modules/videoImport/types/VideoImportBatchEntry.type';
import {
  importHttpUrls, importS3Urls, importVimeoCSV, importVimeoUrls,
} from '@/services/api/importVideo';
import type { ActionContext } from 'vuex';
import type { RootState } from '@/store/type';
import type { CreateVideoOption } from '@/modules/shared/types/video.type';
import type { SavingState } from '@/types/State.type';
import type {
  BaseVideoImportPayload,
  ImportVideoS3Payload,
  ImportVideoVimeoCSVPayload,
  ImportVideoVimeoLinkPayload,
  VideoImportState,
} from '@/modules/videoImport/types/VideoImport.type';

type VideosUploadActionContext = ActionContext<VideoImportState, RootState>

const initialState = (): VideoImportState => ({
  videoLinkList: [],
  videoHttpHeaders: [],
  isVideoLinkListError: false,
  videoS3UrlList: [],
  isVideoS3UrlListError: false,
  videoVimeoUrlList: [],
  isVideoVimeoUrlListError: false,
  selectedS3Source: null,
  importVideoLinkListState: setStateSavingStatusByType(),
  importVideoS3State: setStateSavingStatusByType(),
  importVideoVimeoState: setStateSavingStatusByType(),
  importVideoOptionalFields: {
    overlayPresetId: null,
    geoblockPolicy: null,
    presetData: null,
  },
  exportVideoListFromVimeoData: null,
});

const state = initialState();

const getters = {
  isHaveLinkList(state: VideoImportState): boolean {
    return state.videoLinkList.length > 0;
  },
  isHaveS3UrlList(state: VideoImportState): boolean {
    return state.videoS3UrlList.length > 0;
  },
  isHaveUrlList(state: VideoImportState): boolean {
    return getters.isHaveLinkList(state) || getters.isHaveS3UrlList(state);
  },
};

const mutations = {
  setVideoLinkList(state: VideoImportState, value: string[]) {
    state.videoLinkList = value;
  },
  setVideoHttpHeaders(state: VideoImportState, value: VideoHttpHeader[]) {
    state.videoHttpHeaders = value;
  },
  setVideoS3UrlList(state: VideoImportState, value: string[]) {
    state.videoS3UrlList = value;
  },
  setIsVideoLinkListError(state: VideoImportState, value: boolean) {
    state.isVideoLinkListError = value;
  },
  setSelectedS3Source(state: VideoImportState, value: Nullable<VideoSourceS3>) {
    state.selectedS3Source = value;
  },
  setVideoVimeoUrlList(state: VideoImportState, value: string[]) {
    state.videoVimeoUrlList = value;
  },
  setIsVideoVimeoUrlListError(state: VideoImportState, value: boolean) {
    state.isVideoVimeoUrlListError = value;
  },
  setImportVideoLinkListState(state: VideoImportState, value: SavingState) {
    state.importVideoLinkListState = setStateSavingStatusByType(value);
  },
  setImportVideoS3State(state: VideoImportState, value: SavingState) {
    state.importVideoS3State = setStateSavingStatusByType(value);
  },
  setImportVideoVimeoState(state: VideoImportState, value: SavingState) {
    state.importVideoVimeoState = setStateSavingStatusByType(value);
  },
  setIsVideoS3UrlListError(state: VideoImportState, value: boolean) {
    state.isVideoS3UrlListError = value;
  },
  setVideoImportPresetData(state: VideoImportState, value: CreateVideoOption['presetData']) {
    state.importVideoOptionalFields.presetData = value;
  },
  setVideoImportGeoblockPolicy(state: VideoImportState, value: CreateVideoOption['geoblockPolicy']) {
    state.importVideoOptionalFields.geoblockPolicy = value;
  },
  setVideoImportOverlayPresetId(state: VideoImportState, value: CreateVideoOption['overlayPresetId']) {
    state.importVideoOptionalFields.overlayPresetId = value;
  },
  setExportVideoListFromVimeoData(state: VideoImportState, value: ExportVideoListFromVimeo | LatestExportVideoListFromVimeo | null) {
    state.exportVideoListFromVimeoData = value;
  },
  resetVideoImportState(state: VideoImportState) {
    Object.assign(state, _.cloneDeep(initialState()));
  },
};

const getOptionalVideoImportFields = (state: VideoImportState) => {
  const videoOptions = _.omitBy(state.importVideoOptionalFields, _.isNil);
  const newVideoOptions = {
    ...videoOptions,
    presetId: videoOptions.presetData?.id,
    presetData: undefined,
  };
  return _.isEmpty(newVideoOptions) ? {} : { videoOptions: newVideoOptions };
};

const actions = {
  async importVideoLinkList({ state, commit }: VideosUploadActionContext, payload: BaseVideoImportPayload) {
    commit('setImportVideoLinkListState', { type: 'saving' });
    try {
      const response = await importHttpUrls({
        projectKey: payload.projectKey,
        sourceOptions: {
          type: 'http',
          httpHeaders: _.uniq(state.videoHttpHeaders).filter((obj) => obj.key && obj.value),
        },
        sourceEntries: state.videoLinkList.map((uri) => ({ uri })),
        ...getOptionalVideoImportFields(state),
      });

      await payload.onSuccess(response.data);
      commit('setImportVideoLinkListState', { type: 'success' });
    } catch (error) {
      commit('setImportVideoLinkListState', {
        type: 'error',
        error: formatErrorObject(error, 'Import Video Link List'),
      });
    }
  },
  async importVideoS3({ state, commit }: VideosUploadActionContext, payload: ImportVideoS3Payload) {
    const { onSuccess, ...rest } = payload;
    const connectionId = payload.connectionId || state.selectedS3Source?.id;

    if (!connectionId) {
      commit('setImportVideoS3State', {
        type: 'error',
        error: formatErrorObject(new Error('S3 Connection ID Not Found'), 'Import Video S3 List'),
      });
      return;
    }

    commit('setImportVideoS3State', { type: 'saving' });

    try {
      const response = await importS3Urls({
        ...rest,
        sourceOptions: {
          type: 's3',
          connectionId,
        },
        sourceEntries: state.videoS3UrlList.map((uri) => ({ uri })),
        ...getOptionalVideoImportFields(state),
      });

      await onSuccess(response.data);
      commit('setImportVideoS3State', { type: 'success' });
    } catch (error) {
      commit('setImportVideoS3State', {
        type: 'error',
        error: formatErrorObject(error, 'Import Video S3 List'),
      });
    }
  },
  async importVideoVimeoList({ state, commit }: VideosUploadActionContext, payload: ImportVideoVimeoLinkPayload) {
    commit('setImportVideoVimeoState', { type: 'saving' });
    try {
      const response = await importVimeoUrls({
        projectKey: payload.projectKey,
        sourceOptions: {
          type: 'vimeo',
          accessToken: payload.accessToken,
        },
        sourceEntries: state.videoVimeoUrlList.map((uri) => ({ uri })),
        ...getOptionalVideoImportFields(state),
      });

      await payload.onSuccess(response.data);
      commit('setImportVideoVimeoState', { type: 'success' });
    } catch (error) {
      commit('setImportVideoVimeoState', {
        type: 'error',
        error: formatErrorObject(error, 'Import Video Vimeo List'),
      });
    }
  },
  async importVideoVimeoCSV({ state, commit }: VideosUploadActionContext, payload: ImportVideoVimeoCSVPayload) {
    commit('setImportVideoVimeoState', { type: 'saving' });
    try {

      const data = {
        projectKey: payload.projectKey,
        sourceOptions: {
          type: 'vimeo-csv',
          accessToken: payload.accessToken,
        },
        ...getOptionalVideoImportFields(state),
      };

      const formData = new FormData();
      formData.append('sourceEntries', payload.vimeoCSVFile);
      formData.append('data', JSON.stringify(data));

      const response = await importVimeoCSV(formData);

      await payload.onSuccess(response.data);
      commit('setImportVideoVimeoState', { type: 'success' });
    } catch (error) {
      commit('setImportVideoVimeoState', {
        type: 'error',
        error: formatErrorObject(error, 'Import Video Vimeo List'),
      });
    }
  },
  resetVideoImportState({ commit }: VideosUploadActionContext) {
    commit('resetVideoImportState');
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
